import { PublicKey } from '@solana/web3.js'
import ActionWrapper from '../interfaces/Action'
import NftMetadata from '../interfaces/NftMetadata'
import parseNftName from '../utils/parseNftName'

interface Props {
    nfts: NftMetadata[],
    selectedDragos: NftMetadata[],
    canSelect: boolean,
    selectDrago: any,
    unselectDrago: any,
    name: string
}

const NftList = (props: Props) => {
    return (
        props.nfts.length > 0 ? <div className="nftList">{props.nfts.map(nft =>
            <div key={nft.mint.toBase58()} className="nft">
                <img className="image" src={nft.image} />
                <div className="name">
                    <h4 className='collection'>{parseNftName(nft.name).collection}</h4>
                    <h4 className='index'>{parseNftName(nft.name).index}</h4>
                </div>
                {!props.selectedDragos.map(nft => nft.mint.toBase58()).includes(nft.mint.toBase58())
                    ?
                    <button disabled={!props.canSelect} onClick={() => props.selectDrago.action(nft)} className="actionButton" key={props.selectDrago.name}>{props.selectDrago.name}</button>
                    :
                    <button style={{ backgroundColor: '#C1AAFF' }} onClick={() => props.unselectDrago.action(nft)} className="actionButton unselectButton" key={props.unselectDrago.name}>{props.unselectDrago.name}</button>
                }
            </div>
        )}     </div> : <h3>You don't have any {props.name}</h3>
    )
}

export default NftList