import { PublicKey } from "@solana/web3.js"
import * as metaplex from '@metaplex/js'

export default (nft: metaplex.programs.metadata.MetadataData, allowedPrefixes: string[], allowedCreators: string[]) => {
    if (!nft.data.creators) return false
    let isCreatorValid = false
    for (let creator of nft.data.creators) {
        if (creator.verified && allowedCreators.includes(creator.address)) {
            isCreatorValid = true
            break
        }
    }
    if (!isCreatorValid) return false

    let isPrefixValid = false
    for (let prefix of allowedPrefixes) {
        if (nft.data.name.includes(prefix)) {
            isPrefixValid = true
            break
        }
    }

    return isPrefixValid
}
