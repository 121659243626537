import { useEffect, useState } from 'react'
import * as anchor from '@project-serum/anchor'
import { WalletModalButton, WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import '../css/MintPage.css'
import { useAnchorWallet } from '@solana/wallet-adapter-react'
import PageProps from '../interfaces/PageProps'
import getOwnerNfts from '../utils/getOwnerNfts'
import checkNft from '../utils/checkNft'
import getExternalMetadata from '../utils/getExternalMetadata'
import NftMetadata from '../interfaces/NftMetadata'
import NftList from '../components/NftList'
import { COSMIC_PREFIXES, DRAGOS_CREATORS, DRAGOS_PREFIXES, PROGRAM_ID, MINTS_NAMES, MINTS_STOCK, COST } from '../vars'
import { PublicKey } from '@solana/web3.js'
import InfoModal from '../components/InfoModal'
import { Action } from '../interfaces/Action'
import getErrorText from '../utils/getErrorText'
import sendAndConfirmTx from '../utils/sendAndConfirmTx'
import { Hydramint, IDL } from '../idl'
import actions from '../actions'

interface MintPageProps extends PageProps {
    index: number,
    nft_prefixes: string[],
    canReserve: (selectedDragos: NftMetadata[]) => boolean,
    reserveFunction: (provider: anchor.AnchorProvider, program: anchor.Program<Hydramint>, mints: anchor.web3.PublicKey[]) => Promise<anchor.web3.Transaction>
}

const MintPage = (props: MintPageProps) => {
    const [provider, setProvider] = useState<anchor.AnchorProvider>()
    const [program, setProgram] = useState<anchor.Program<Hydramint>>()
    const [stats, setStats] = useState<number[]>([])
    const [selectedDragos, setSelectedDragos] = useState<NftMetadata[]>([])
    const [walletDragos, setWalletDragos] = useState<Array<NftMetadata>>([])
    const [loadingStates, setLoadingStates] = useState({
        walletDragos: false
    })
    const [isSelecting, setIsSelecting] = useState(false)
    const [modalNotification, setModalNotification] = useState<{ status: string, text: string, action?: any }>({ status: '', text: '' })

    const wallet = useAnchorWallet()


    const reset = () => {
        setWalletDragos([])
        setStats([])
        setSelectedDragos([])
    }
    const selectDrago = (nft: NftMetadata) => {
        setSelectedDragos(old => [...old, nft])
    }
    const unselectDrago = (nft: NftMetadata) => {
        setSelectedDragos(old => old.filter(curMint => !curMint.mint.equals(nft.mint)))
    }
    const loadStats = async (program: anchor.Program<Hydramint>) => {
        if (!wallet) return

        const [statsAccount] = await PublicKey.findProgramAddress([Buffer.from('stats')], PROGRAM_ID)
        try {
            const { minted, reserved } = await program.account.statsAccount.fetch(statsAccount)
            setStats([minted[0] + reserved[0], minted[1] + reserved[1], minted[2] + reserved[2]])
        } catch (e) {
            setStats([0, 0, 0])
        }
    }
    const loadNfts = async (provider: anchor.Provider) => {
        if (!wallet) return

        setLoadingStates(loadingStates => ({ ...loadingStates, walletDragos: true }))

        const nfts = await getOwnerNfts(provider.connection, wallet.publicKey)
        nfts.forEach(async nft => {
            if (!(checkNft(nft, props.nft_prefixes, DRAGOS_CREATORS))) {
                return
            }
            const nftExternalMetadata = await getExternalMetadata(nft)
            if (!nftExternalMetadata) return

            setWalletDragos(walletDragos => [...walletDragos, nftExternalMetadata])
        })

        setLoadingStates(loadingStates => ({ ...loadingStates, walletDragos: false, walletLairs: false }))
    }
    const reserve = async () => {
        if (!program || !wallet || !provider) return

        try {
            const mints = selectedDragos.map(nft => new PublicKey(nft.mint))
            const tx = await props.reserveFunction(provider, program, mints)
            tx.recentBlockhash = !tx.recentBlockhash ? (await provider.connection.getLatestBlockhash()).blockhash : tx.recentBlockhash
            tx.feePayer = !tx.feePayer ? provider.wallet.publicKey : tx.feePayer
            const signedTx = await provider.wallet.signTransaction(tx)
            setModalNotification({ status: 'wait', text: 'Confirming Transaction...' })
            await sendAndConfirmTx(provider.connection, signedTx, { skipPreflight: true })
            setModalNotification({ status: 'success', text: 'Reserve Completed. Confirm Mint Now' })
            const resultMint = anchor.web3.Keypair.generate()
            const tx2 = await actions.mintReserved(provider, program, props.index, resultMint)
            tx2.recentBlockhash = !tx2.recentBlockhash ? (await provider.connection.getLatestBlockhash()).blockhash : tx2.recentBlockhash
            tx2.feePayer = !tx2.feePayer ? provider.wallet.publicKey : tx2.feePayer
            tx2.partialSign(resultMint)
            const signedTx2 = await provider.wallet.signTransaction(tx2)
            setModalNotification({ status: 'wait', text: 'Confirming Transaction...' })
            await sendAndConfirmTx(provider.connection, signedTx2, { skipPreflight: true })
            setModalNotification({ status: 'success', text: 'Transaction Completed' })
            reset()
            loadNfts(provider)
            loadStats(program)
        } catch (e: any) {
            console.log(e)
            setModalNotification({ status: 'error', text: getErrorText(e) })
        }
    }

    useEffect(() => {
        if (!wallet) return
        if (provider?.wallet.publicKey.equals(wallet.publicKey)) return

        const newProvider = new anchor.AnchorProvider(props.connection, wallet, { commitment: 'confirmed' })
        const newProgram = new anchor.Program(IDL, PROGRAM_ID, newProvider)

        anchor.setProvider(newProvider)
        setProvider(newProvider)
        setProgram(newProgram)

        loadNfts(newProvider)
        loadStats(newProgram)
    }, [wallet])


    return (
        <div className="Page Home">
            <div className="data">
                <div className="banner">
                    <img className='bannerImg' src={`/img/${MINTS_NAMES[props.index].toLowerCase()}.gif`} />
                </div>
                {wallet && wallet.publicKey ? <> {program && stats.length > 0 ? <div className="selectWrapper">
                    <div className='select'>
                        {stats ? <div className="stats">
                            <h2>Minted {stats[props.index]}/{MINTS_STOCK[props.index]}</h2>
                            <h2>Each one costs {COST[props.index]}</h2>
                        </div> : null}
                        {stats[props.index] < MINTS_STOCK[props.index] ?
                            <>
                                {!isSelecting
                                    ?
                                    <button onClick={() => setIsSelecting(true)} className='actionButton'>Select Dragos</button>
                                    :
                                    <div className='dragos'>
                                        {loadingStates.walletDragos ?
                                            <h3>Loading Dragos...</h3> :
                                            <>
                                                <NftList name='Dragos' nfts={walletDragos} canSelect={!props.canReserve(selectedDragos)} selectedDragos={selectedDragos} selectDrago={{ action: (nft: NftMetadata) => selectDrago(nft), name: 'Select' }} unselectDrago={{ action: (nft: NftMetadata) => unselectDrago(nft), name: 'Unselect' }} />
                                                {props.canReserve(selectedDragos) ? <button onClick={reserve} className='actionButton reserveButton'>Reserve {MINTS_NAMES[props.index]}</button> : null}
                                            </>
                                        }
                                    </div>
                                } </> : null}
                    </div>
                </div> : <div className="selectWrapper">
                    <h3>Loading...</h3>
                </div>} </> : <div className="selectWrapper"><WalletMultiButton /> </div>}
            </div>
            {modalNotification.status ? <InfoModal modalNotification={modalNotification} setModalNotification={setModalNotification} /> : null}
        </div>
    )
}

export default MintPage