import { capitalize } from 'lodash';
import '../css/InfoModal.css'

enum ModalStatus {
    success = 'success'
}

interface Props {
    modalNotification: { status: string, text: string, action?: any },
    setModalNotification: React.Dispatch<React.SetStateAction<{
        status: string;
        text: string;
        action?: any;
    }>>
}

const InfoModal = (props: Props) => {
    const confirm = () => {
        if (props.modalNotification.action) {
            props.modalNotification.action()
        }
        props.setModalNotification({ status: "", text: "", action: null })
    }
    return (
        <div className="infoModalWrapper">
            <div className='infoModal'>
                <div className="header">
                    <h2 className='status'>{capitalize(props.modalNotification.status)}</h2>
                    <span onClick={() => props.setModalNotification({ status: "", text: "", action: null })} className='closeButton'>X</span>
                </div>
                <div className='infoWrapper'>
                    <div className='info'>
                        <img className='statusImg' src={`/img/${props.modalNotification.status}.png`} />
                        <p className='infoText'>{props.modalNotification.text}</p>
                        <button onClick={confirm} className='actionButton'>Ok</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InfoModal