import mintReserved from "./mintReserved";
import reserveCosmic from "./reserveCosmic";
import reserveRoyal from "./reserveRoyal";
import reserveUnique from "./reserveUnique";

export default {
    reserveCosmic,
    reserveRoyal,
    reserveUnique,
    mintReserved
}