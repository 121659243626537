import { createDefaultAuthorizationResultCache, SolanaMobileWalletAdapter } from '@solana-mobile/wallet-adapter-mobile';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import {
    GlowWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { clusterApiUrl, Connection } from '@solana/web3.js';
import React, { FC, ReactNode, useMemo } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Navbar from './components/Navbar';
import Cosmic from './pages/MintPage';
import { COSMIC_PREFIXES, DRAGOS_PREFIXES, NETWORK, RPC } from './vars';
import './css/Nft.css'
import MintPage from './pages/MintPage';
import actions from './actions';
import NftMetadata from './interfaces/NftMetadata';
import Home from './pages/Home';

require('./css/App.css');
require('@solana/wallet-adapter-react-ui/styles.css');

const App = () => {

    const network = NETWORK as WalletAdapterNetwork
    const rpc = RPC
    const connection = new Connection(rpc)

    const wallets = [
        new SolanaMobileWalletAdapter({
            appIdentity: { name: 'Dapp' },
            authorizationResultCache: createDefaultAuthorizationResultCache(),
        }),
        new PhantomWalletAdapter(),
        new GlowWalletAdapter(),
        new SlopeWalletAdapter(),
        new SolflareWalletAdapter({ network }),
        new TorusWalletAdapter(),
    ]

    return (
        <ConnectionProvider endpoint={rpc}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    <div className="App">
                        <Navbar />
                        <BrowserRouter>
                            <Routes>
                                <Route path='/cosmic' element={<MintPage connection={connection} index={0} nft_prefixes={COSMIC_PREFIXES} reserveFunction={actions.reserveCosmic} canReserve={(selectedDragos: NftMetadata[]) => selectedDragos.length == 5} />} />
                                <Route path='/royal' element={<MintPage connection={connection} index={1} nft_prefixes={DRAGOS_PREFIXES} reserveFunction={actions.reserveRoyal} canReserve={(selectedDragos: NftMetadata[]) => {
                                    if (selectedDragos.length == 0) return false
                                    return (selectedDragos.filter(nft => nft.name.includes('Xmas MetaDrago') || nft.name.includes('MetaDrago Mutant')).length >= 1 && selectedDragos.length == 5)
                                }} />} />
                                <Route path='/unique' element={<MintPage connection={connection} index={2} nft_prefixes={DRAGOS_PREFIXES} reserveFunction={actions.reserveUnique} canReserve={(selectedDragos: NftMetadata[]) => selectedDragos.length == 5} />} />
                                <Route path='/' element={<Home connection={connection} />} />
                            </Routes>
                        </BrowserRouter>
                    </div>
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    )
}
export default App;