import * as anchor from "@project-serum/anchor";
import { PublicKey, Transaction } from "@solana/web3.js";
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Hydramint } from "../idl";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import getMetadata from "../utils/getMetadata";
import * as metaplex from '@metaplex/js'
import { EMBER_MINT, TOKEN_METADATA_PROGRAM_ID } from "../vars";

export default async (provider: anchor.AnchorProvider, program: anchor.Program<Hydramint>, mints: PublicKey[]): Promise<Transaction> => {
    const { wallet } = provider
    const initializerEmberAta = await Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, EMBER_MINT, wallet.publicKey)

    const [mintAccount, mintAccountBump] = await anchor.web3.PublicKey.findProgramAddress([Buffer.from("mint_account"), wallet.publicKey.toBuffer()], program.programId)
    const [stats] = await anchor.web3.PublicKey.findProgramAddress([Buffer.from("stats")], program.programId)
    let dragoAccounts: any = {}
    for (let index in mints) {
        const dragoAta = await Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, mints[index], wallet.publicKey)

        dragoAccounts[`drago${Number(index) + 1}Mint`] = mints[index]
        dragoAccounts[`drago${Number(index) + 1}Metadata`] = await metaplex.programs.metadata.Metadata.getPDA(mints[index])
        dragoAccounts[`drago${Number(index) + 1}Ata`] = dragoAta
    }

    const ix = await program.methods.reserveRoyal().accounts({
        initializer: wallet.publicKey,
        emberMint: EMBER_MINT,
        initializerEmberAta,
        mintAccount,
        stats,
        ...dragoAccounts,
        tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID
    }).instruction()

    console.log({
        initializer: wallet.publicKey,
        emberMint: EMBER_MINT.toBase58(),
        initializerEmberAta: initializerEmberAta.toBase58(),
        mintAccount: mintAccount.toBase58(),
        stats: stats.toBase58(),
        ...dragoAccounts,
        tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID
    })

    const tx = new anchor.web3.Transaction()
    tx.add(anchor.web3.ComputeBudgetProgram.requestUnits({
        units: 600000,
        additionalFee: 0
    }))
    tx.add(ix)

    return tx
}