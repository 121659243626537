import { PublicKey } from "@solana/web3.js"

export const NETWORK = 'mainnet-beta'
export const RPC = 'https://ssc-dao.genesysgo.net'

export const PROGRAM_ID = new PublicKey('9KuWzntHXHG1XKHy42q6TsGF43WFo7WhipdSVhuGS3G7')
export const EMBER_MINT = new PublicKey('Emberc567ToSP9FgAMuJWHXiQnEKVWrWC6gy64zbsK8u')
export const TOKEN_METADATA_PROGRAM_ID = new PublicKey("metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s")
export const UPDATE_AUTHORITY = new PublicKey("4mbwQaW9jG5vpKRdzG5KwbwnLWAKpYAiXVPVziahYTr1")

export const MINTS_NAMES = [
    'Cosmic',
    'Royal',
    'Unique'
]
export const MINTS_STOCK = [
    10,
    20,
    25
]
export const COST = [
    '5 Ice dragos/Mutants + 1000 $EMBER',
    '4 GEN-2s + 1 Ice Drago/Mutant + 1000 $Ember',
    '5 GeN-2s + 1000 $EMBER'
]
export const DRAGOS_CREATORS = [
    '4g8EqmBkXz4uncG8CqhPfTbnyweVbVYcaLRE63xaB3fn',
    'C3g9SmSu8GfwfJKeKguga76Wq9sHj9M63Jbged95yEhR',
    '5dGSfWBSJHoVzmuoGVNxAKHDtojMT6MrWqWeX28csN1Y',
    'BS1F4vpTmHG55GBPfRcCk1sVYUQ3dRRLUvPySztMHvek',
    '81AKiT9nkL2ajnvy38JjnQyfdBxU29oNups4qFsDEEUN',
    'Gw2mS8kH8SjuX4KxQR7Kkxwau8RaRvGJuMmnY2LBfYo7', // GEN-1
    '4mbwQaW9jG5vpKRdzG5KwbwnLWAKpYAiXVPVziahYTr1' // test one
]
export const DRAGOS_PREFIXES = [
    'Xmas MetaDrago',
    'MetaDrago GEN-2',
    'MetaDrago Mutant'
]
export const COSMIC_PREFIXES = [
    'Xmas MetaDrago',
    'MetaDrago Mutant'
]