import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Link } from 'react-router-dom';
import '../css/Navbar.css'

const Navbar = () => {
    return (
        <div className="navbar">
            <a href='/'><div className='brand'>
                <img className='logo' src='/img/logo.png' />
                <h4 className='name'>MetaDrago</h4>
            </div></a>
            <WalletMultiButton />
        </div>
    )
}

export default Navbar