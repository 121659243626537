export type Hydramint = {
  "version": "0.1.0",
  "name": "hydramint",
  "instructions": [
    {
      "name": "reserveCosmic",
      "accounts": [
        {
          "name": "initializer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "drago1Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago1Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago1Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago2Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago2Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago2Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago3Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago3Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago3Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago4Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago4Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago4Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago5Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago5Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago5Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "emberMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "initializerEmberAta",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "stats",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "reserveRoyal",
      "accounts": [
        {
          "name": "initializer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "drago1Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago1Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago1Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago2Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago2Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago2Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago3Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago3Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago3Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago4Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago4Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago4Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago5Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago5Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago5Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "emberMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "initializerEmberAta",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "stats",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "reserveUnique",
      "accounts": [
        {
          "name": "initializer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "drago1Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago1Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago1Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago2Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago2Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago2Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago3Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago3Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago3Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago4Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago4Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago4Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago5Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago5Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago5Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "emberMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "initializerEmberAta",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "stats",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "mintReserved",
      "accounts": [
        {
          "name": "initializer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "updateAuthority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mintAuthority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "resultMint",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "resultMasterEdition",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "resultMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "resultAta",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "stats",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "nftType",
          "type": "u8"
        },
        {
          "name": "mintAuthorityBump",
          "type": "u8"
        }
      ]
    }
  ],
  "accounts": [
    {
      "name": "statsAccount",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "reserved",
            "type": {
              "array": [
                "u8",
                3
              ]
            }
          },
          {
            "name": "minted",
            "type": {
              "array": [
                "u8",
                3
              ]
            }
          }
        ]
      }
    },
    {
      "name": "mintAccount",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "reserved",
            "type": {
              "array": [
                "u8",
                3
              ]
            }
          }
        ]
      }
    }
  ],
  "types": [
    {
      "name": "MintErrors",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "NotEnoughUniqueDragos"
          },
          {
            "name": "DragosMintedOut"
          },
          {
            "name": "WrongNftType"
          },
          {
            "name": "NoReservedNfts"
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "WrongMetadataAccount",
      "msg": "Wrong metadata account"
    },
    {
      "code": 6001,
      "name": "WrongCollection",
      "msg": "Wrong collection"
    },
    {
      "code": 6002,
      "name": "NoAllowedCreator",
      "msg": "No allowed creator"
    },
    {
      "code": 6003,
      "name": "WrongTraitData",
      "msg": "Wrong trait data"
    }
  ]
};

export const IDL: Hydramint = {
  "version": "0.1.0",
  "name": "hydramint",
  "instructions": [
    {
      "name": "reserveCosmic",
      "accounts": [
        {
          "name": "initializer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "drago1Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago1Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago1Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago2Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago2Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago2Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago3Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago3Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago3Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago4Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago4Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago4Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago5Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago5Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago5Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "emberMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "initializerEmberAta",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "stats",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "reserveRoyal",
      "accounts": [
        {
          "name": "initializer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "drago1Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago1Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago1Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago2Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago2Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago2Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago3Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago3Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago3Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago4Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago4Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago4Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago5Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago5Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago5Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "emberMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "initializerEmberAta",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "stats",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "reserveUnique",
      "accounts": [
        {
          "name": "initializer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "drago1Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago1Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago1Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago2Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago2Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago2Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago3Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago3Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago3Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago4Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago4Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago4Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago5Mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago5Metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "drago5Ata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "emberMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "initializerEmberAta",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "stats",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "mintReserved",
      "accounts": [
        {
          "name": "initializer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "updateAuthority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mintAuthority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "resultMint",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "resultMasterEdition",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "resultMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "resultAta",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "stats",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "nftType",
          "type": "u8"
        },
        {
          "name": "mintAuthorityBump",
          "type": "u8"
        }
      ]
    }
  ],
  "accounts": [
    {
      "name": "statsAccount",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "reserved",
            "type": {
              "array": [
                "u8",
                3
              ]
            }
          },
          {
            "name": "minted",
            "type": {
              "array": [
                "u8",
                3
              ]
            }
          }
        ]
      }
    },
    {
      "name": "mintAccount",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "reserved",
            "type": {
              "array": [
                "u8",
                3
              ]
            }
          }
        ]
      }
    }
  ],
  "types": [
    {
      "name": "MintErrors",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "NotEnoughUniqueDragos"
          },
          {
            "name": "DragosMintedOut"
          },
          {
            "name": "WrongNftType"
          },
          {
            "name": "NoReservedNfts"
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "WrongMetadataAccount",
      "msg": "Wrong metadata account"
    },
    {
      "code": 6001,
      "name": "WrongCollection",
      "msg": "Wrong collection"
    },
    {
      "code": 6002,
      "name": "NoAllowedCreator",
      "msg": "No allowed creator"
    },
    {
      "code": 6003,
      "name": "WrongTraitData",
      "msg": "Wrong trait data"
    }
  ]
};
