import * as anchor from "@project-serum/anchor";
import { PublicKey, Transaction } from "@solana/web3.js";
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Hydramint } from "../idl";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import getMetadata from "../utils/getMetadata";
import * as metaplex from '@metaplex/js'
import { EMBER_MINT, TOKEN_METADATA_PROGRAM_ID, UPDATE_AUTHORITY } from "../vars";

export default async (provider: anchor.AnchorProvider, program: anchor.Program<Hydramint>, mintType: number, resultMint: anchor.web3.Keypair): Promise<Transaction> => {
    const { wallet } = provider

    const resultAta = await Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, resultMint.publicKey, wallet.publicKey)

    const [mintAccount, mintAccountBump] = await anchor.web3.PublicKey.findProgramAddress([Buffer.from("mint_account"), wallet.publicKey.toBuffer()], program.programId)
    const [mintAuthority, mintAuthorityBump] = await anchor.web3.PublicKey.findProgramAddress([Buffer.from("mint_authority")], program.programId)
    const [stats] = await anchor.web3.PublicKey.findProgramAddress([Buffer.from("stats")], program.programId)

    const tx = await program.methods.mintReserved(mintType, mintAuthorityBump).accounts({
        initializer: wallet.publicKey,
        mintAuthority,
        updateAuthority: UPDATE_AUTHORITY,
        mintAccount,
        resultMint: resultMint.publicKey,
        resultMetadata: await metaplex.programs.metadata.Metadata.getPDA(resultMint.publicKey),
        resultMasterEdition: await metaplex.programs.metadata.MasterEdition.getPDA(resultMint.publicKey),
        resultAta,
        stats,
        tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID
    }).transaction()

    return tx
}