import { useEffect, useState } from 'react'
import * as anchor from '@project-serum/anchor'
import { WalletModalButton, WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import '../css/Home.css'
import { useAnchorWallet } from '@solana/wallet-adapter-react'
import PageProps from '../interfaces/PageProps'
import getOwnerNfts from '../utils/getOwnerNfts'
import checkNft from '../utils/checkNft'
import getExternalMetadata from '../utils/getExternalMetadata'
import NftMetadata from '../interfaces/NftMetadata'
import NftList from '../components/NftList'
import { COSMIC_PREFIXES, DRAGOS_CREATORS, DRAGOS_PREFIXES, PROGRAM_ID, MINTS_NAMES, MINTS_STOCK, COST } from '../vars'
import { PublicKey } from '@solana/web3.js'
import InfoModal from '../components/InfoModal'
import { Action } from '../interfaces/Action'
import getErrorText from '../utils/getErrorText'
import sendAndConfirmTx from '../utils/sendAndConfirmTx'
import { Hydramint, IDL } from '../idl'
import actions from '../actions'
import { Link } from 'react-router-dom'

const Home = (props: PageProps) => {
    const [provider, setProvider] = useState<anchor.AnchorProvider>()
    const [program, setProgram] = useState<anchor.Program<Hydramint>>()
    const [stats, setStats] = useState<number[]>([])
    const [userStats, setUserStats] = useState<{ minted: number[], reserved: number[] }>()
    const [loadingStates, setLoadingStates] = useState({
        walletDragos: false
    })
    const [isSelecting, setIsSelecting] = useState(false)
    const [modalNotification, setModalNotification] = useState<{ status: string, text: string, action?: any }>({ status: '', text: '' })

    const wallet = useAnchorWallet()


    const reset = () => {
        setStats([])
        setUserStats({ minted: [], reserved: [] })
    }
    const loadStats = async (program: anchor.Program<Hydramint>) => {
        if (!wallet) return

        const [statsAccount] = await PublicKey.findProgramAddress([Buffer.from('stats')], PROGRAM_ID)
        try {
            const { minted, reserved } = await program.account.statsAccount.fetch(statsAccount)
            setStats([minted[0] + reserved[0], minted[1] + reserved[1], minted[2] + reserved[2]])
        } catch (e) {
            setStats([0, 0, 0])
        }
    }
    const loadUserStats = async (program: anchor.Program<Hydramint>) => {
        if (!wallet) return

        const [mintAccount] = await PublicKey.findProgramAddress([Buffer.from('mint_account'), wallet.publicKey.toBuffer()], PROGRAM_ID)
        try {
            const { minted, reserved } = await program.account.mintAccount.fetch(mintAccount)
            setUserStats({ minted, reserved })
        } catch (e) {
            setUserStats({ minted: [0, 0, 0], reserved: [0, 0, 0] })
        }
    }
    const mintReserved = async (mintType: number) => {
        if (!program || !wallet || !provider) return

        try {
            const resultMint = anchor.web3.Keypair.generate()
            const tx = await actions.mintReserved(provider, program, mintType, resultMint)
            tx.recentBlockhash = !tx.recentBlockhash ? (await provider.connection.getLatestBlockhash()).blockhash : tx.recentBlockhash
            tx.feePayer = !tx.feePayer ? provider.wallet.publicKey : tx.feePayer
            tx.partialSign(resultMint)
            const signedTx = await provider.wallet.signTransaction(tx)
            setModalNotification({ status: 'wait', text: 'Confirming Transaction...' })
            await sendAndConfirmTx(provider.connection, signedTx, { skipPreflight: true })
            setModalNotification({ status: 'success', text: 'Transaction Completed' })
            reset()
            loadStats(program)
            loadUserStats(program)
        } catch (e: any) {
            console.log(e)
            setModalNotification({ status: 'error', text: getErrorText(e) })
        }
    }

    useEffect(() => {
        if (!wallet) return
        if (provider?.wallet.publicKey.equals(wallet.publicKey)) return


        const newProvider = new anchor.AnchorProvider(props.connection, wallet, { commitment: 'confirmed' })
        const newProgram = new anchor.Program(IDL, PROGRAM_ID, newProvider)

        anchor.setProvider(newProvider)
        setProvider(newProvider)
        setProgram(newProgram)

        loadStats(newProgram)
        loadUserStats(newProgram)
    }, [wallet])


    return (
        <div className="Page">
            {wallet ? <div className="mintData">
                {userStats && userStats.reserved.reduce((prev, cur) => prev + cur, 0) ? <div className="mints">
                    <h2 className='title'><span className='highlight'>Y</span>our Reserves</h2>
                    {MINTS_NAMES.map((v, index) =>
                        userStats.reserved[index] ?
                            <div className='mint'>
                                <h3>You reserved <span className='highlight'>{userStats?.reserved[index]} {MINTS_NAMES[index]}</span> Overlord</h3>
                                <button onClick={() => mintReserved(index)} className='actionButton'>Mint</button>
                            </div> : null
                    )}
                </div> : null}
                <h2 className='title' style={{ marginBottom: 0 }}><span className='highlight'>O</span>verlord Mints</h2>
                {stats.length ? <div className="mintList">
                    {MINTS_NAMES.map((v, index) =>
                        <div className="mintType">
                            <h2>{MINTS_NAMES[index]}</h2>
                            <img className='mintTypeImg' src={`/img/${MINTS_NAMES[index].toLowerCase()}.gif`} />
                            <h4>{COST[index]}</h4>
                            <h2 className='mintsCount'>Minted {stats[index]}/{MINTS_STOCK[index]}</h2>
                            <Link to={`/${MINTS_NAMES[index].toLowerCase()}`}><button className='actionButton'>Select</button></Link>
                        </div>)}
                </div> : null}
            </div> :
                <div className="greeting">
                    <h1 className='collection'>Overlords</h1>
                    <h3 className='description'>The Time has finally come<br />250 GEN-2 MetaDragos must be harvested to awaken the Overlord collection.<br />The end of times is near, and the age of Overlords is<br />inevitable</h3>
                    <h3 className='question'>Will you remain livestock, or seek dominion?</h3>
                    <WalletMultiButton />
                </div>}
            {modalNotification.status ? <InfoModal modalNotification={modalNotification} setModalNotification={setModalNotification} /> : null}
        </div>
    )
}

export default Home